@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");

@layer base {
    @font-face {
        font-family: "Gaming";
        src: url('../public/fonts/Gaming.ttf');
    }
}